<template>
    <form method="post" class="login" @submit.prevent="postLogin">
        <input type="text" name="username" placeholder="Bruger" v-model="username" :disabled="processing" autocomplete="username">
        <input type="password" name="password" placeholder="Kode" v-model="password" :disabled="processing" autocomplete="current-password">
        <p v-if="failed">Kunne ikke logge ind</p>
        <button :disabled="processing" type="submit"><span v-text="processing ? 'LOGGER IND' : 'LOG IND'"></span>
            <font-awesome-icon style="margin-left: .3rem;" v-if="processing" icon="circle-notch" spin/>
        </button>
    </form>
</template>

<script>
    export default {
        name: 'login',
        data: function () {
            return {
                username: null,
                password: null,
                failed: false,
                processing: false,
            };
        },
        methods: {
            postLogin: function () {
                this.failed = false;
                this.processing = true;
                fetch(process.env.VUE_APP_SAFEWATCH_UI_BASE + '/login', {
                    method: 'POST',
                    body: JSON.stringify({username: this.username, password: this.password}),
                    headers: {'Content-Type': 'application/json'}
                }).then(res => {
                    if (res.status === 200) {
                        res.json().then(sessionKey => {
                            this.$emit('session-created', sessionKey);
                        })
                    } else {
                        this.failed = true;
                    }
                }).catch(() => {
                    this.failed = true;
                }).finally(() => {
                    this.processing = false;
                });

            }
        }
    }
</script>

<style scoped lang="scss">
    @import '../styles/scss/mixins';
    @import '../styles/scss/colors';

    .login {
        background-color: #fff;
        display: flex;
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
        margin-top: 3.5rem;
        width: 16rem;
        padding: 1rem 2rem;
        @include elevated;

        > * {
            margin-top: 1rem;
            margin-bottom: 1rem;
        }

        input, button {
            padding: 1rem;

            &:not([type=submit]) {
                font-size: medium;
            }
        }

        button[type=submit] {
            background-color: $primary;
            border: none;
            color: #fff;
            letter-spacing: 1px;
            &[disabled] {
                opacity: .4;
            }
        }

        p {
            font-size: small;
        }

    }
</style>
