<template>
    <section class="card">
        <div class="header"><h1>{{ title }}</h1>
            <div class="current-readings">
                <div class="sensor">
                    <div class="descriptor">pH</div>
                    <div class="value">{{ hasReadings('ph') ? getLatestReading('ph').data : '-' }}</div>
                </div>
                <div class="sensor">
                    <div class="descriptor">klor</div>
                    <div class="value">{{ hasReadings('cl') ? getLatestReading('cl').data : '-' }} <span class="small">mg/l</span>
                    </div>
                </div>
                <div class="time">{{ latestRegTime }}</div>
            </div>
        </div>
        <CardSelector class="selector" @card-date-selected="(d) => {$emit('card-date-selected', d)}"></CardSelector>
        <div class="charts">
            <div v-if="refreshing" class="refresh-overlay"><font-awesome-icon class="loader" spin size="10x"
                                                                              :icon="['fas','spinner']"/></div>
            <LineChart class="chart" :date="displayDate" :title="getSensorTypeTitle(sensor.type)" v-for="sensor of sensors"
                       v-bind:key="sensor.id" :sensor="sensor">
            </LineChart>
        </div>
    </section>
</template>

<script>
    import CardSelector from "./CardSelector";
    import LineChart from "./LineChart";

    export default {
        name: 'DeviceCard',
        components: {CardSelector, LineChart},
        props: {
            title: String,
            data: Object,
            sensors: Array,
            displayDate: Date,
            refreshing: Boolean,
        },
        methods: {
            /**
             * Precondition: sensor type must exist and have readings (hasReadings === true)
             *
             * @param sensorType
             * @returns {*}
             */
            getLatestReading: function (sensorType) {
                let reducer = (clLatest, clReading) => {
                    if (clLatest === null) {
                        return clReading;
                    } else {
                        if (clLatest.reg_time < clReading.reg_time) {
                            return clReading;
                        } else {
                            return clLatest;
                        }
                    }
                };
                let val = this.getSensor(sensorType).readings.reduce(reducer, null);
                return val;
            },
            getSensor(type) {
                return this.sensors.find(s => s.type === type);
            },
            hasReadings(sensorType) {
                return this.getSensor(sensorType) && this.getSensor(sensorType).readings && this.getSensor(sensorType).readings.length > 0;
            },
            getSensorTypeTitle(sensorType) {
                switch (sensorType) {
                    case 'ph':
                        return 'pH';
                    case 'cl':
                        return 'Klor';
                    default:
                        return '-';
                }
            },
        },
        computed: {
            latestRegTime: function () {
                if (!this.hasReadings('ph')) {
                    return '--:--';
                } else {
                    let latestDate = new Date(this.getLatestReading('ph').reg_time * 1000);
                    return latestDate.getHours().toString().padStart(2, '0') + ':' + latestDate.getMinutes().toString().padStart(2, '0');
                }

            }
        }
    }
</script>

<style scoped lang="scss">
    .card {
        background-color: #fff;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        max-width: 60rem;
        display: flex;
        width: 100%;
        flex-direction: column;
        border-radius: 6px;
        margin-top: 2rem;
        margin-bottom: 2rem;

        .header {
            font-size: 1.1rem;
            padding: 1.5rem 1rem;
            display: flex;
            justify-content: space-between;
            h1 {
                margin-right: 1rem;
            }
        }

        .charts {
            display: flex;
            align-items: center;
            justify-content: space-around;
            flex-wrap: wrap;
            padding-top: 2rem;
            padding-bottom: 2rem;
            position: relative;

            .chart {
                position: relative;
                height: 300px;
                /*margin: auto;*/
                box-sizing: border-box;
                width: 100%;
                padding-bottom: 1rem;
                max-width: 25rem;
                box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
                margin: 1rem;
                border-right: 1rem solid transparent;
                border-left: 1rem solid transparent;
            }
            .refresh-overlay {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                opacity: .2;
                background-color: #000;
                display: flex;
                align-items: center;
                justify-content: center;

                .loader {
                    color: white;
                }
            }
        }

        .current-readings {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;
            font-size: 1rem;

            .sensor {
                display: flex;
                margin-right: 1.5rem;
            }

            .descriptor {
                font-size: .8rem;
                color: #bbb;
                margin-right: .3rem;
                /*margin-left: 1.5rem;*/
            }

            .value {
                .small {
                    font-size: .8rem;
                }
            }

            .time {
                /*margin-left: 1rem;*/
                color: #bbb;
            }
        }
    }
</style>
