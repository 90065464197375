<template>
    <div id="app">
        <header id="top">
            <section class="start">
                <!--                <font-awesome-icon icon="bars"/>-->
                <span class="title"><a href="/">Aquatronic Safewatch</a></span>
            </section>
            <section class="end">
                <span @click="logout" v-if="!emailId && authenticated">Log ud</span>
            </section>
        </header>
        <div id="top-spacer"></div>
        <main class="dashboard" v-if="authenticated && !emailId">
            <card :title="device.name" v-for="device in devices" :refreshing="refreshing"
                  :display-date="deviceDisplayDates[device.id]" v-bind:key="device.id"
                  :sensors="device.sensors"
                  @card-date-selected="(d) => {dateUpdated(d, device)}"></card>
            <!--            <LineChart2 style="background-color: lightgoldenrodyellow;" title="Test"-->
            <!--                        :chart-data="chartData2"></LineChart2>-->
        </main>
        <login class="login" v-on:session-created="auth" v-if="!emailId && !authenticated"></login>
        <suspend v-if="emailId" :email-id="emailId"/>
    </div>
</template>

<script>
    import Card from "./components/Card";
    import Login from "./components/Login";
    import Suspend from "./components/Suspend";
    import Vue from "vue";

    // import LineChart2 from "./components/LineChart2";

    export default {
        name: 'app',
        components: {
            Card, Login, Suspend
        },
        data: function () {
            return {
                authenticated: false,
                timer: null,
                devices: null,
                deviceDisplayDates: {},
                refreshMs: 10000,
                refreshing: false,
                emailId: null,
            };
        },
        created: function () {
            if (window.location.pathname.substring(0, 8) === '/suspend') {
                this.emailId = window.location.pathname.substring(9)
            } else {
                this.initSession()
                    .then(() => {
                        this.setTimer(this.refreshMs)
                    });
            }
        },

        methods: {
            dateUpdated: function (date, device) {
                this.$set(this.deviceDisplayDates, device.id, date);
                this.refreshing = true
                this.setTimer(this.refreshMs)
                    .then(() => {
                        this.refreshing = false
                    })
            },
            initSession: function () {
                if (this.getSessionKey() === null) {
                    this.$sessionStorage.set('session', null);
                }
                return this.updateSession();
            },
            updateSession: function () {
                this.authenticated = this.getSessionKey() !== null;
                return this.loadCustomerDevices();
            },
            getSessionKey: function () {
                return this.$sessionStorage.get('session', null);
            },
            auth: function (sessionKey) {
                this.$sessionStorage.set('session', sessionKey);
                this.updateSession().then(() => {
                    this.setTimer(this.refreshMs)
                })
            },
            logout: function () {
                this.$sessionStorage.remove('session');
                this.updateSession();
                this.devices = null;
                if (this.timer) {
                    clearInterval(this.timer);
                    this.timer = null;
                }
            },
            setTimer: function (updateMs) {
                if (this.timer) {
                    clearInterval(this.timer);
                }

                let fn = function () {
                    if (this.authenticated) {
                        return this.updateData(); // Current date. Change later..
                    }

                }.bind(this);
                let firstResult = fn();
                this.timer = setInterval(fn, updateMs);
                return firstResult
            },
            updateData: function () {
                let requests = [];
                (this.devices || []).forEach(device => {
                    let d = this.deviceDisplayDates[device.id] || (new Date())
                    let dateStr = [
                        d.getFullYear(),
                        ('0' + (d.getMonth() + 1)).slice(-2),
                        ('0' + d.getDate()).slice(-2)
                    ].join('-')

                    device.sensors.forEach(sensor => {
                        requests.push(this.authFetch(process.env.VUE_APP_SAFEWATCH_UI_BASE + `/readings/sensor/${sensor.id}?date=${dateStr}`)
                            .then(res => res.json())
                            .then(readings => {
                                if (!Object.prototype.hasOwnProperty.call(sensor, 'readings')) {
                                    // console.log('Set first readings');
                                    Vue.set(sensor, 'readings', readings)
                                } else {
                                    let removeReadings = sensor.readings.filter(rExisting => !readings.some(rNew => rNew.id === rExisting.id))
                                    for (let remove of removeReadings) {
                                        sensor.readings.splice(sensor.readings.indexOf(remove), 1)
                                    }
                                    let added = readings.filter(r => !sensor.readings.find(r2 => r2.id === r.id));
                                    if (added.length > 0) {
                                        sensor.readings.push(...added);
                                    }
                                }
                            }))
                    })
                });
                return Promise.all(requests);
            },
            loadCustomerDevices: function () {
                if (this.authenticated) {
                    return this.authFetch(process.env.VUE_APP_SAFEWATCH_UI_BASE + '/devices')
                        .then(res => res.json())
                        .then(devices => {
                            this.devices = devices;
                        });
                } else {
                    this.devices = null;
                    return Promise.resolve('Cleared current devices');
                }
            },
            authFetch: function (url, options) {
                let h = new Headers(
                    {Authorization: 'Bearer ' + this.getSessionKey()}
                );
                return fetch(url, Object.assign({headers: h}, options));
            },
            getRandomInt() {
                return Math.floor(Math.random() * (50 - 5 + 1)) + 5
            }
        },
        beforeDestroy() {
            clearInterval(this.timer);
        }
    }
</script>

<style lang="scss">
@import "styles/scss/reset";
@import 'styles/scss/mixins';
@import 'styles/scss/colors';

$break-small: 600px;
$break-medium: 1024px;
$break-large: 1440px;
$break-xlarge: 1920px;

@media (min-width: $break-small) {
    .dashboard {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
}

.dashboard {
    padding-top: 1.5em;
    /*margin: 0 auto 0 auto;*/

    display: flex;
    flex-direction: column;
    align-items: center;
}

#app {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    min-height: 100vh;
    background-color: #f8f8f8;
    /*padding-top: 3.5rem;*/
}

#top-spacer {
    width: 100%;
}

#top, #top-spacer {
    height: 3.5rem;
}

#top {
    box-sizing: border-box;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: change-color($primary, $alpha: .96);
    z-index: 99;
    color: #fff;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    @include elevated;

    .start {
        /*display: flex;*/
        /*align-items: center;*/

        > *:not(:first-child) {
            margin-left: 1rem;
        }

        .title {
            font-size: 1.2rem;
        }
    }

    .end span {
        cursor: pointer;
    }

}

</style>
