<template>
    <div class="chooser">
        <div class="dates">
            <date-pick locale="da-DK" v-model="date" :months="monthNames" :weekdays="weekdayNames"
                       :input-attributes="{'class': 'date-chosen'}"
                       @input="$emit('card-date-selected', new Date(Date.parse(date)))"/>
        </div>
    </div>
</template>

<script>

    import DatePick from 'vue-date-pick'

    export default {


        name: 'selector',
        components: {DatePick},
        data: function () {
            return {
                date: this.getDateStr(new Date()),
                monthNames: [],
                weekdayNames: [],
            }
        },
        created: function () {
            let monthFormat = new Intl.DateTimeFormat('da-DK', {month: 'long'})
            let weekdayFormat = new Intl.DateTimeFormat('da-DK', {weekday: 'short'})
            let testDate = new Date(Date.UTC(2000, 0, 1, 0, 0, 0));
            for (let month = 0; month < 12; month++) {
                this.monthNames.push(monthFormat.format(testDate))
                testDate.setMonth(testDate.getMonth() + 1)
            }

            for (let day = 0; day < 7; day++) {
                this.weekdayNames.push(weekdayFormat.format(testDate))
                testDate.setDate(testDate.getDate() + 1)
            }

        },
        methods: {
            getDateStr(d) {
                return [
                    d.getFullYear(),
                    ('0' + (d.getMonth() + 1)).slice(-2),
                    ('0' + d.getDate()).slice(-2)
                ].join('-')
            }
        }
    }
</script>

<style lang="scss">
    @import '~vue-date-pick/src/vueDatePick.scss';
    // language=SCSS

    $bc: #dfdfdf;
    $selected: #2863dc;
    .chooser {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        background-color: rgb(225, 234, 243);
        padding: .5rem 1rem;
        border-top: 1px solid $bc;
        border-bottom: 1px solid $bc;
    }

    .intervals {
        display: flex;
        flex-direction: row;
        border-radius: 6px;
        overflow: hidden;
        background-color: #fff;
        font-size: .8rem;
        border: 1px solid $bc;

        .selectable {
            display: flex;
            height: 2rem;
            width: 4rem;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &.selected {
                background-color: $selected;
                color: #fff;
            }

            &:not(:last-child) {
                border-right: 1px solid $bc;
            }

        }
    }

    .dates {
        display: flex;

        .date-chosen {
            border-left: 1px solid $bc;
            border-right: 1px solid $bc;
            border-top: 1px solid $bc;
            border-bottom: 1px solid $bc;
            border-left: none;
            border-right: none;
            height: 2rem;
            background-color: #fff;
            padding: 0 .5rem 0 1.5rem;
            width: 5rem;
        }

        .vdpComponent.vdpWithInput {
            &::before {
                content: '📅';
                font-size: inherit;
                top: 50%;
                transform: translateY(-50%);
                left: .5rem;
                position: absolute;
            }

            .vdpClearInput {
                display: none;
            }

            > input {
                padding-right: 0;
            }
        }

    }
</style>
