<script>
import {Line} from 'vue-chartjs'
import pan from 'chartjs-plugin-zoom'
import zoom from 'chartjs-plugin-zoom'

export default {
  name: 'LineChart',
  extends: Line,
  props: ['title', 'min', 'max', 'sensor', 'date'],
  computed: {
    sensorChartData() {
      let sorter = (a, b) => {
        return a.reg_time - b.reg_time
      }
      let data = []
      if (this.sensor.readings) {
        data = Array.prototype.slice.call(this.sensor.readings)
            .sort(sorter)
            .map(r => ({
              x: new Date(r.reg_time * 1000),
              y: r.data
            }))
      }
      return {
        datasets: [
          {
            pointRadius: 1,
            label: this.sensor.type,
            fill: false,
            data: data,
          }
        ]
      }
    },
    dateMin() {
      let d = this.date || new Date()
      return new Date(d.getFullYear(), d.getMonth(), d.getDate(), 10, 0, 0)
    },
    dateMax() {
      let d = this.date || new Date()
      return new Date(d.getFullYear(), d.getMonth(), d.getDate(), 16, 0, 0)
    },
    options() {
      return {
        animation: {
          duration: 0
        },
        pan: {
          enabled: true,
          mode: 'x',
          // rangeMax: {
          // x: (new Date(2019, 11, 4, 23, 50, 0)).getTime(),
          // },
          // scaleAxes: 'x',
        },
        zoom: {
          enabled: false,
        },
        maintainAspectRatio: false,
        responsive: true,
        tooltips: {
          intersect: false,
        },
        title: {
          display: true,
          text: this.title,
        },
        legend: {
          display: false,
        },
        scales: {
          xAxes: [{
            type: 'time',
            // distribution: 'linear',
            time: {
              unit: 'hour',
              tooltipFormat: 'YYYY-MM-DD HH:mm',
              displayFormats: {
                hour: 'HH:mm'
              },
              stepSize: 1,
            },
            // bounds: 'ticks',
            ticks: {
              min: this.dateMin,
              max: this.dateMax,
              // source: 'data',
              stepSize: 0.5,
              color: '#0A5',
              maxTicksLimit: 6,
            },
          }
          ],
          yAxes: [{
            ticks: {
              min: this.sensorTypeMin(this.sensor.type), max: this.sensorTypeMax(this.sensor.type),
              maxTicksLimit: 6,
              step: 4
            },
            scaleLabel: {}
          }]
        }

      };
    }
  },
  watch: {
    sensorChartData: function () {
      this.renderChart(this.sensorChartData, this.options)
    },
    date: function () {
      this.renderChart(this.sensorChartData, this.options)
    }
  },
  methods: {
    sensorTypeMin: function (type) {
      if (type === 'ph') {
        return 6;
      } else if (type === 'cl') {
        return 0;
      }
      return 0
    },
    sensorTypeMax: function (type) {
      if (type === 'ph') {
        return 8;
      } else if (type === 'cl') {
        return 3;
      }
      return 1
    }
  },
  mounted() {
    this.addPlugin(pan);
    this.addPlugin(zoom);
    this.renderChart(this.sensorChartData, this.options);
  }
}
</script>

<style lang="scss">

</style>
