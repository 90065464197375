<template>
    <div class="suspend">
        <div>Sluk for denne type beskeder i</div>
        <div style="margin: 1rem 0;">
            <select style="font-size: 1.5rem;padding: .5rem;width: 100%;" :disabled="suspending" v-model.number="hours">
                <option value="1" selected>1 time</option>
                <option value="2">2 timer</option>
                <option value="4">4 timer</option>
                <option value="6">6 timer</option>
                <option value="9">9 timer</option>
                <option value="12">12 timer</option>
                <option value="24">1 døgn</option>
                <option value="48">2 døgn</option>
                <option value="168">1 uge</option>
            </select>
        </div>
        <button
            type="button"
            style="padding-top: .5rem;padding-bottom: .5rem;"
            :disabled="suspending"
            @click="suspend"
        >
            <font-awesome-icon v-if="suspending" spin :icon="['fas','spinner']"/>
            Sluk{{ suspending ? 'ker' : '' }}
        </button>
        <div class="message success" v-if="suspended === true">
            <font-awesome-icon color="#18a118" :icon="['far','check-circle']"/>
            <span>Slukket</span>
        </div>
        <div class="message failure" v-if="suspended === false">
            <font-awesome-icon color="#bd0606" :icon="['far','times-circle']"/>
            <span>Fejl!</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'suspend',
        data: function () {
            return {
                suspending: false,
                suspended: null,
                hours: 1,
            };
        },
        props: {
            emailId: {
                type: String,
                required: true,
            },
        },
        methods: {
            suspend() {
                const options = {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        emailId: this.emailId,
                        hours: this.hours,
                    })
                }
                this.suspending = true
                fetch(process.env.VUE_APP_SAFEWATCH_UI_BASE + '/suspend', options)
                    .then(res => {
                        this.suspended = res.status === 204
                    })
                    .finally(() => {
                        this.suspending = false
                    })
            }
        }
    }
</script>

<style scoped lang="scss">
@import '../styles/scss/mixins';
@import '../styles/scss/colors';

.suspend {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3.5rem;
    width: 16rem;
    padding: 2rem;
    @include elevated;

    button[type=button] {
        background-color: $primary;
        border: none;
        color: #fff;
        letter-spacing: 1px;
        cursor: pointer;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);

        &:hover {
            opacity: .9;
        }

        &[disabled] {
            opacity: .4;
        }

    }

    .chip {
        font-family: monospace;
        color: white;
        display: inline-flex;
        align-items: center;
        border-radius: 1rem;
        background-color: #999;
        padding: .2rem .5rem;
    }

}

.message {

    border-radius: .2rem;
    margin-top: 2rem;
    display: flex;
    align-items: center;
    color: #636363;

    & > * {
        padding: .5rem 0 .5rem .75rem;
    }

    &.success {
        background-color: rgba(75, 215, 21, 0.1);
        border-left: 3px solid #06bd06;
    }

    &.failure {
        background-color: rgba(215, 21, 21, 0.1);
        border-left: 3px solid #bd0606;
    }
}
</style>
