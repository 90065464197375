import Vue from 'vue'
import App from './App.vue'

import {library} from '@fortawesome/fontawesome-svg-core'
import {faCalendarAlt, faCheckCircle, faTimesCircle} from '@fortawesome/free-regular-svg-icons'
import {faCircleNotch, faSpinner} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import Storage from 'vue-web-storage'
import 'whatwg-fetch'

Vue.config.productionTip = false

library.add(faCalendarAlt)
library.add(faCircleNotch)
library.add(faSpinner)
library.add(faCheckCircle)
library.add(faTimesCircle)

Vue.use(Storage, {
    drivers: ['session']
});
Vue.component('font-awesome-icon', FontAwesomeIcon);

new Vue({
    render: h => h(App),
}).$mount('#app');
